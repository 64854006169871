import {
    realNameAuth
} from '@/utils/user.js';
export default {
    name: "TaxCommit",
    data() {
        return {
            isAgree: false,
            cardNum: "",
            realName: ""
        }
    },
    created() {
    },
    methods: {
        testId(id) {
            // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
            var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
            //号码规则校验
            if (!format.test(id)) {
                return { 'status': 0, 'msg': '身份证号码不合规' };
            }
            //区位码校验
            //出生年月日校验  前正则限制起始年份为1900;
            var year = id.substr(6, 4),//身份证年
                month = id.substr(10, 2),//身份证月
                date = id.substr(12, 2),//身份证日
                time = Date.parse(month + '-' + date + '-' + year),//身份证日期时间戳date
                now_time = Date.parse(new Date()),//当前时间戳
                dates = (new Date(year, month, 0)).getDate();//身份证当月天数
            if (time > now_time || date > dates) {
                return { 'status': 0, 'msg': '出生日期不合规' }
            }
            //校验码判断
            var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);  //系数
            var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'); //校验码对照表
            var id_array = id.split("");
            var sum = 0;
            for (var k = 0; k < 17; k++) {
                sum += parseInt(id_array[k]) * parseInt(c[k]);
            }
            if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
                return { 'status': 0, 'msg': '身份证校验码不合规' }
            }
            return { 'status': 1, 'msg': '校验通过' }
        },
        handleAgree() {
            this.isAgree = this.isAgree ? false : true
        },
        herfFun() {
            window.location.href = "/agreement/auth.html"
        },
        sureFun() {
            if (this.isAgree) {
                if (!this.realName) {
                    return this.$toast("请输入姓名")
                  }
                if (!this.cardNum) {
                  return this.$toast("请输入二代身份证号码")
                }
                const obj = this.testId(this.cardNum)
                if (obj.status === 0) {
                    return this.$toast.fail(obj.msg)
                }
                let data = {
                    memberId: this.$cookies.get('memberId'),
                    realname: this.realName,
                    idNo: this.cardNum
                };
                realNameAuth(data).then(res => {
                    if (res.code == "0") {
                        this.$toast.success('实名认证成功')
                        setTimeout(() => {
                          this.$router.go(-1)
                        }, 400) 
                    }
                })
            }
        }
    }
}