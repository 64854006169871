<template>
    <div class="name-certification">
       <p class="tip">必读提示</p>
       <p class="des first">1.根据国家法律、法规的要求，电子商务经营需本人真实有效的身份认证。<br>2.为确保交易及资金安全，请务必填写真实信息，实名认证后将无法再修改。冒用他人身份，将自行承担法律责任。<br>3.港澳台用户，使用港澳台居民来往内地通行证进行认证。</p>
       <div class="name-box">
         <div class="name-li flex align-center">
           <p>证件类型</p>
           <input value="二代身份证" type="text" readonly>
         </div>
         <div class="name-li flex align-center">
           <p>真实姓名</p>
           <input v-model="realName" type="text" placeholder="请输入姓名">
         </div>
         <div class="name-li flex align-center last-li">
           <p>证件号码</p>
           <input v-model="cardNum" type="text" placeholder="请输入二代身份证号码">
         </div>
       </div>
       <div class="fixed">
        <div class="tax-b flex align-center flex-center">
            <img
            v-show="!isAgree"
            src="@/assets/img/loginRegister/no-select.png"
            @click="handleAgree"
          />
          <img
            v-show="isAgree"
            src="@/assets/img/loginRegister/select.png"
            @click="handleAgree"
          />
            <p @click="handleAgree">我已经阅读并了解<span @click="herfFun">《实名认证用途》</span></p>
        </div>
        <div :class="['b-last flex align-center', {'b-red': isAgree}]" @click="sureFun">
            <p>确认实名</p>
        </div>
       </div>

    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>